import React from 'react'

const Downloaderror = () => {
  return (
    <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
       marginTop:"200px",
       marginBottom:"100px",
        width: "100%",
        flexDirection: 'column' 
      }}><>
      <h1>Not Able to Download? </h1>
    <p style={{ textAlign: "center" }}>
      <strong>
        <span style={{ fontSize: "12pt" }}>Follow These steps :</span>
      </strong>
    </p>
    <br></br>
    <br></br>
    <p style={{ textAlign: "center" }}>
      <strong>
        <span style={{ color: "#ff6600" }}>STEP 1</span> : Click on the lock icon
        on top left address bar.
      </strong>
    </p>
    <br></br>
    <p style={{ textAlign: "center" }}>
      <strong>
        <img
          className="aligncenter wp-image-1589"
          src="https://shrinkus.tk/wp-content/uploads/2022/11/step-1.png"
          alt=""
          width={582}
          height={328}
        />
      </strong>
    </p>
    <br></br>
    <br></br>
    <p style={{ textAlign: "center" }}>
      <strong>
        <span style={{ color: "#ff6600" }}>STEP 2</span>: Click on site Settings .
      </strong>
    </p>
    <br></br>
    <p style={{ textAlign: "center" }}>
      <strong>
        <img
          className="wp-image-1593 aligncenter"
          src="https://shrinkus.tk/wp-content/uploads/2022/11/step2.jpg"
          alt=""
          width={593}
          height={164}
        />
      </strong>
    </p>
    <br></br>
    <br></br>
    <p style={{ textAlign: "center" }}>
      <strong>
        <span style={{ color: "#ff6600" }}>STEP 3:</span> Change the permission of
        pop-ups and re-directs &amp; Ads to allow.
      </strong>
    </p>
    <br></br>
    <p style={{ textAlign: "center" }}>
    <strong>
      <img
        className="wp-image-1594 aligncenter"
        src="https://shrinkus.tk/wp-content/uploads/2022/11/STEP3.jpg"
        alt=""
        width={445}
        height={344}
      />
    </strong>
    </p>
    <br></br>
    <a style={{color:'red'}} href='https://vimeo.com/754999957'>Still having issue? </a>
  </>
  </div>
  )
}

export default Downloaderror
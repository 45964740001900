import React from 'react';

import './movie-card.scss';
import placeholder from '../../assets/blur.jpg'
import placeholder1 from '../../assets/sfgif.gif'
import { Link } from 'react-router-dom';

import Button from '../button/Button';
import { padding } from '@mui/system';
import PreloadImage from '../../PreloadImage';




const MovieCard = props => {

    const item  = props.item;

    const link = '/' + props.category + '/'+item.id;
    const name=item.attributes.movieName||item.attributes.seriesName;
    const category=item.attributes.category||item.attributes.seriesCategory;
    const bg = props.category==='movie'? item.attributes.poster || item.attributes.backdrop:item.attributes.seriesPoster || item.attributes.seriesBackdrop;
    // style={{backgroundImage: `url(${bg})`}}
    return (
        <Link to={link}>
            <div className="movie-card" > 
            <PreloadImage  lazy
                src={bg} 
             placeholder={placeholder1}></PreloadImage>
                <Button>
             <i className="bx bx-play"></i>
         </Button>
                
          </div> 
            <h3>{name}<div className='lan'><span>{category}</span></div></h3>
        </Link>
    );
}

export default MovieCard;

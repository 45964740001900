import React from 'react';

import { useParams } from 'react-router';

import PageHeader from '../components/page-header/PageHeader';


import MovieGrid from '../components/movie-grid/MovieGrid';

const Catalog = () => {

    const propspar = useParams();
    const category=propspar.category;
    var type=propspar.type;
    if(!type){
        type='All'
    }
    return (
        <>
            <PageHeader>
               <h1></h1>
            </PageHeader>
            <div className="container">
                <div className="section mb-3">
                    <MovieGrid category={category} type={type}/>
                </div>
            </div>
        </>
    );
}

export default Catalog;

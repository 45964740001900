import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router";
import StarIcon from "@mui/icons-material/Star";
import "./detail.scss";
import axios from "axios";
import Seasons from "./Seasons";
import PropagateLoader from "react-spinners/PropagateLoader";
import MovieList from "../../components/movie-list/MovieList";
import Button, { OutlineButton } from "../../components/button/Button";
import Adcomp from "../../ads/Adcomp";
import hdimage from "../../assets/1080-HD.jpg";
import downimage from "../../assets/gdrive1.gif";
import downtele from "../../assets/teleim.gif";
import telelogo from "../../assets/telegramlogo.gif";
import playimg from "../../assets/play.gif";
import downimg from "../../assets/down.gif";
import urlExist from "url-exist";
import { BrowserView, MobileView } from "react-device-detect";

import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { Helmet } from "react-helmet";
import CountDownPage from "./CountDownPage";
import PreloadImage from "../../PreloadImage";
import placeholder1 from "../../assets/sfgif.gif";
import placeholder2 from "../../assets/bgsfgif.gif";
const Parse = require("parse");
// const Banner=()=>{
//   const banner = useRef(HTMLDivElement)

//   useEffect(() => {
//   if (!banner.current.firstChild) {
//       const script = document.createElement('script')
//       script.type = 'text/javascript'
//       script.src =`//p454064.clksite.com/adServe/banners?tid=454064_888198_0&type=floating_banner&size=6&side=right&position=bottom`
//       // script["data-cfasync"] = false;
//       // script.async=true;
//       if (banner.current) {
//           banner.current.append(script)
//       }
//   }
// })
// return (<>

//   <div ref={banner}></div>
//   <div id="">
//         </div>
//  </>);
// }
// const Banner1=()=>{
//   const banner1 = useRef(HTMLDivElement)

//   useEffect(() => {
//   if (!banner1.current.firstChild) {
//       const script = document.createElement('script')
//       script.type = 'text/javascript'
//       script.src =`//p454064.clksite.com/adServe/banners?tid=454064_888198_6`
//       script["data-cfasync"] = false;
//       script.async=true;
//       if (banner1.current) {
//           banner1.current.append(script)
//       }
//   }
// })
// return (<>

//   <div  ref={banner1}></div>
//   <div  id="">
//         </div>
//  </>);
// }

const Detail = () => {
  const history = useHistory();

  const { category, id } = useParams();
  const [item, setItem] = useState(null);
  const [season, setSeason] = useState();
  const [hdseason, sethdseason] = useState();
  const [streamwishseason, setstreamwishseason] = useState();
  const [streamrubyseason, setstreamrubyseason] = useState();
  const [filelionsseason, setfilelionsseason] = useState();
  const [loading, setLoading] = useState(true);
  const [hdloading, sethdloading] = useState(false);
  const [dloading, setdloading] = useState(false);
  const [countDp, setCountDp] = useState(false);
  const [urlCount, setUrlCount] = useState();
  const [hideurl, setHideUrl] = useState();
  const [uploadEverurl, setuploadEverUrl] = useState();
  const [streamwishurl, setstreamwishurl] = useState();
  const [streamrubyurl, setstreamrubyurl] = useState();
  const [filelionsurl, setfilelionsurl] = useState();
  const [hubcloud, sethubcloudurl] = useState();
  const [upnShare, setupnShare] = useState();
  const [aditems, setaditems] = useState([]);
  const setModalActive = async () => {
    var url = item.attributes.streamlink;
    url = url.replace("play", "d");
    window.open(url, "_blank")?.focus();
  };
  // useEffect(()=>{
  //   const script = document.createElement('script');
  //   script.src ="https://rndskittytor.com/400/5308343";
  //   script.async = true;
  //   script.setAttribute('data-cfasync',false)
  //   // <script async="async" data-cfasync="false" src="//upgulpinon.com/1?z=5308352"></script>
  //   // script.setAttribute('data-zone',5198851)

  //     try{document.body.appendChild(script);
  //  return ( document.body.removeChild(script))}
  //  catch{

  //  }

  // })
  //   useEffect(()=>{
  //     const getAds = async () => {
  //       const adClass = Parse.Object.extend("Ads");
  //       const query = new Parse.Query(adClass);
  //       query.limit(50);
  //       var results=await query.find().then((results)=>{setaditems(results);
  //           }).catch((e)=>{console.log(e)});
  // }

  // getAds();
  //   },[])
  useEffect(() => {
    setLoading(true);
    const getDetail = async () => {
      if (category === "movie") {
        try {
          // const Movies = Parse.Object.extend("latestMovies");
          const query = new Parse.Query("movies");
          const results = await query.get(id);
          setItem(results);
          //  console.log(results.attributes.streamhide);
          setLoading(false);
        } catch {
          try {
            const query = new Parse.Query("latestMovies");
            const results = await query.get(id);
            setItem(results);
            //  console.log(results);
            //  console.log(results.attributes.streamhide);
            setLoading(false);
          } catch {
            try {
              const query = new Parse.Query("movieSlider");
              const results = await query.get(id);
              setItem(results);
              setLoading(false);
            } catch {
              console.log("error");
              setLoading(true);
            }
          }
        }
      } else {
        try {
          // const Movies = Parse.Object.extend("latestMovies");
          const query = new Parse.Query("series");
          const results = await query.get(id);
          setItem(results);
          setSeason(results.attributes.Seasons);
          sethdseason(results.attributes.upnShare);
          setstreamwishseason(results.attributes.streamwish);
          setfilelionsseason(results.attributes.filelions);
          setstreamrubyseason(results.attributes.streamruby);
          console.log(results);
          setLoading(false);
        } catch {
          try {
            const query = new Parse.Query("seriesSlider");
            var results = await query.get(id);
            // console.log(results);
            var seriesName = results.attributes.seriesName;
            //console.log(seriesName);
            const Movies = Parse.Object.extend("series");
            const query1 = new Parse.Query(Movies);
            query1.equalTo("seriesName", seriesName);
            results = await query1.find();

            //console.log(results[0].attributes.Seasons);
            setItem(results[0]);
            setSeason(results[0].attributes.Seasons);
            sethdseason(results[0].attributes.upnShare);
            setstreamwishseason(results[0].attributes.streamwish);
            setfilelionsseason(results[0].attributes.filelions);
            setstreamrubyseason(results[0].attributes.streamruby);

            setLoading(false);
          } catch {
            console.log("error");
            setLoading(true);
          }
        }
      }
    };
    getDetail();
  }, [category, id]);
  const urlCheck = async (url) => {
    const exists = await urlExist(url);
    console.log(exists);
  };
  const playHandler = (value, value2, value3, value4, value5) => {
    if (!value2) {
      value2 = "nothing";
    }
    if (!value3) {
      value3 = "nothing";
    }
    if (!value4) {
      value4 = "nothing";
    }
    if (!value5) {
      value5 = "nothing";
    }
    try {
      var temp = value.split("/");
      var idval = temp[4].split(".");
      var playid = "nothing";
      history.push(`/play/${playid}-${value2}-${value3}-${value4}-${value5}`);
    } catch (error) {
      var errorMessage = error;
      console.log(error);
    }
  };
  const dHandler = () => {
    setdloading(true);
    var url = item.attributes.streamlink;
    var upnSharurl = item.attributes.upnShare;
    var streamwishurl = item.attributes.streamwish;
    var uploadeverurl = item.attributes.uploadever;
    var filelionsurl = item.attributes.filelions;
    var streamrubylink = item.attributes.streamruby;
    var hubcloud = item.attributes.HubCloud;
    var temp = url.split("/");
    var idval = temp[4].split(".");
    var playid = idval[0];
    url = `https://vidhidepro.com/d/${filelionsurl}.html`;
    var shrink = item.attributes.shrink;
    if (shrink) {
      const apicall = `https://shrinkme.io/api?api=5f859d1a8a03b012370cbe649bd1a6dfcac85fe6&url=${url}`;
      axios(apicall)
        .then((response) => {
          var shorturl = response.data.shortenedUrl;
          setdloading(false);
          setCountDp(true);
          setUrlCount(shorturl || url);
          // window.open(shorturl||url, "_blank")?.focus();
        })
        .catch((error) => console.log(error));
    } else {
      setdloading(false);
      setCountDp(true);
      setUrlCount(url);
      setHideUrl(streamwishurl);
      setuploadEverUrl(uploadeverurl);
      setstreamrubyurl(streamrubylink);
      setstreamwishurl(streamwishurl);
      sethubcloudurl(hubcloud);
      setupnShare(upnSharurl);
      // window.open(url, "_blank")?.focus();
    }
  };
  const hubcloudHandler = () => {
    sethdloading(true);
    var shrink = item.attributes.shrink;
    var hddownloadlink = item.attributes.HubCloud;
    const words = hddownloadlink.split("/");
    var hdlink = `https://showflix.shop/archives/${words[4]}`;
    // if(shrink)
    // {
    const apicall = `https://shrinkme.io/api?api=5f859d1a8a03b012370cbe649bd1a6dfcac85fe6&url=${hdlink}`;
    axios(apicall)
      .then((response) => {
        var shorturl = response.data.shortenedUrl;
        sethdloading(false);
        setCountDp(true);
        setUrlCount(shorturl || item.attributes.HubCloud);
        //window.open(shorturl||item.attributes.hdlink, "_blank")?.focus();
      })
      .catch((error) => console.log(error));
    //}
    // else {
    //   setCountDp(true);
    //   setUrlCount(item.attributes.hdlink);// window.open(item.attributes.hdlink, "_blank")?.focus();
    //   sethdloading(false);}
  };
  const hdHandler = () => {
    sethdloading(true);
    var shrink = item.attributes.shrink;
    var hddownloadlink = item.attributes.hdlink;
    const words = hddownloadlink.split("/");
    var hdlink = `https://showflix.shop/archives/${words[4]}`;
    // if(shrink)
    // {
    const apicall = `https://shrinkme.io/api?api=5f859d1a8a03b012370cbe649bd1a6dfcac85fe6&url=${hdlink}`;
    axios(apicall)
      .then((response) => {
        var shorturl = response.data.shortenedUrl;
        sethdloading(false);
        setCountDp(true);
        setUrlCount(shorturl || item.attributes.hdlink);
        //window.open(shorturl||item.attributes.hdlink, "_blank")?.focus();
      })
      .catch((error) => console.log(error));
    //}
    // else {
    //   setCountDp(true);
    //   setUrlCount(item.attributes.hdlink);// window.open(item.attributes.hdlink, "_blank")?.focus();
    //   sethdloading(false);}
  };
  const telegramlink = () => {
    window.open("https://telegram.me/showflix_movie", "_blank")?.focus();
  };
  const BannerVintage = () => {
    const bannervintage = useRef(HTMLDivElement);
    useEffect(() => {
      if (!bannervintage.current.firstChild) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `https://glizauvo.net/401/7635797`;
        script.async = true;
        // script.setAttribute('data-zone',7635797);

        if (bannervintage.current) {
          bannervintage.current.append(script);
        }
      }
    });
    return (
      <>
        <div ref={bannervintage}></div>
        {/* <div id="7635797"></div> */}
      </>
    );
  };
  // useEffect(async ()=>{
  //  return(<script src="https://jsc.adskeeper.co.uk/s/h/showflix.in.1396580.js" async>
  //   </script>)
  // },[]);

  return (
    <>
      {!countDp ? (
        <>
          {!loading ? (
            <>
              <div
                className="banner"
                style={{
                  backgroundImage: `url(${
                    category === "movie"
                      ? item.attributes.backdrop || item.attributes.poster
                      : item.attributes.seriesBackdrop ||
                        item.attributes.seriesPoster
                  })`,
                }}
              ></div>
              <div className="mb-3 movie-content container">
                <div className="movie-content__poster">
                  <div
                  // className="movie-content__poster__img"
                  // style={{
                  //   backgroundImage:  `url(${ category === "movie"? item.attributes.poster || item.attributes.backdrop: item.attributes.seriesPoster ||item.attributes.seriesBackdrop})`
                  // }}
                  >
                    <PreloadImage
                      lazy
                      src={
                        item.attributes.poster || item.attributes.seriesPoster
                      }
                      placeholder={placeholder1}
                    ></PreloadImage>
                  </div>
                </div>
                <div className="movie-content__info">
                  <h1 className="title">
                    {item.attributes.movieName || item.attributes.seriesName}
                  </h1>
                  <div className="genres">
                    <span className="genres__item">
                      {item.attributes.category ||
                        item.attributes.seriesCategory}
                    </span>
                  </div>
                  <div>
                    <div className="genres__rating">
                      <div style={{ paddingRight: "1px", paddingLeft: "1px" }}>
                        {item.attributes.rating || item.attributes.seriesRating}
                      </div>
                      <div>
                        <StarIcon
                          style={{ color: "yellow", blockSize: 12 }}
                        ></StarIcon>
                      </div>
                    </div>
                  </div>
                  <p className="overview">
                    {item.attributes.storyline ||
                      item.attributes.seriesStoryline}
                  </p>
                  {item.attributes.Note && (
                    <h3 style={{ color: "red" }}>
                      {" "}
                      Note ⚠️ : {item.attributes.Note}{" "}
                    </h3>
                  )}
                  <br></br>
                  {item.attributes.hdlink && (
                    <div>
                      <span
                        style={{
                          color: "#FFFFDE",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        (For Fast Download 🚀 & High Quality use G-Drive link)
                      </span>
                    </div>
                  )}
                  {category === "movie" ? (
                    <>
                      <div className="btns">
                        <Button
                          onClick={() =>
                            playHandler(
                              item.attributes.streamlink,
                              item.attributes.upnShare,
                              item.attributes.streamwish,
                              item.attributes.filelions,
                              item.attributes.streamruby
                            )
                          }
                        >
                          <img
                            style={{
                              maxWidth: 75,
                              maxHeight: 25,
                              borderRadius: 5,
                            }}
                            src={playimg}
                            alt="Play"
                          />{" "}
                          <span>Play</span>
                        </Button>
                        <OutlineButton onClick={dHandler}>
                          {dloading ? (
                            <span>Getting Link...</span>
                          ) : (
                            <>
                              <img
                                style={{
                                  maxWidth: 75,
                                  maxHeight: 25,
                                  borderRadius: 5,
                                }}
                                src={downimg}
                                alt="Download"
                              />{" "}
                              <span>Download</span>
                            </>
                          )}
                        </OutlineButton>
                        {/* <img style={{maxWidth:75,maxHeight:25,paddingRight:5}} src={downimage} alt="HD Download"></img><img style={{maxWidth:75,maxHeight:25}} src={hdimage} alt="HD Download" /> */}
                        {item.attributes.hdlink && (
                          <OutlineButton className="butn" onClick={hdHandler}>
                            {" "}
                            {hdloading ? (
                              <span>Getting Link...</span>
                            ) : (
                              <>
                                {" "}
                                <img
                                  style={{
                                    maxWidth: 75,
                                    maxHeight: 25,
                                    borderRadius: 5,
                                  }}
                                  src={downimage}
                                  alt="HD Download"
                                />{" "}
                                <span>G - Drive</span>{" "}
                              </>
                            )}
                          </OutlineButton>
                        )}
                        {item.attributes.HubCloud && (
                          <OutlineButton
                            className="butn"
                            onClick={hubcloudHandler}
                          >
                            {" "}
                            {hdloading ? (
                              <span>Getting Link...</span>
                            ) : (
                              <>
                                {" "}
                                <img
                                  style={{
                                    maxWidth: 75,
                                    maxHeight: 25,
                                    borderRadius: 5,
                                  }}
                                  src={downtele}
                                  alt="HD Download"
                                />{" "}
                                <span>HubCloud & Telegram</span>{" "}
                              </>
                            )}
                          </OutlineButton>
                        )}
                      </div>
                      <br></br>
                      <span style={{ fontWeight: "bold" }}>Share : </span>
                      <span style={{ paddingLeft: 5 }}> </span>
                      <FacebookShareButton
                        quote={`${item.attributes.movieName} - ${item.attributes.category} 𝕄𝕠𝕧𝕚𝕖 - 𝐒𝐇𝐎𝐖𝐅𝐋𝐈𝐗`}
                        url={`${window.location.href}`}
                      >
                        <FacebookIcon size={36} borderRadius={10} />
                      </FacebookShareButton>
                      <span style={{ paddingLeft: 5 }}> </span>
                      <TelegramShareButton
                        title={`${item.attributes.movieName} - ${item.attributes.category} 𝕄𝕠𝕧𝕚𝕖 - 𝐒𝐇𝐎𝐖𝐅𝐋𝐈𝐗`}
                        url={`${window.location.href}`}
                      >
                        <TelegramIcon size={36} borderRadius={10} />
                      </TelegramShareButton>
                      <span style={{ paddingLeft: 5 }}> </span>
                      <TwitterShareButton
                        title={`${item.attributes.movieName} - ${item.attributes.category} 𝕄𝕠𝕧𝕚𝕖 - 𝐒𝐇𝐎𝐖𝐅𝐋𝐈𝐗`}
                        url={`${window.location.href}`}
                      >
                        <TwitterIcon size={36} borderRadius={10}></TwitterIcon>
                      </TwitterShareButton>
                      <span style={{ paddingLeft: 5 }}> </span>
                      <WhatsappShareButton
                        title={`${item.attributes.movieName} - ${item.attributes.category} 𝕄𝕠𝕧𝕚𝕖 - 𝐒𝐇𝐎𝐖𝐅𝐋𝐈𝐗`}
                        separator=":: "
                        url={`${window.location.href}`}
                      >
                        <WhatsappIcon
                          size={36}
                          borderRadius={10}
                        ></WhatsappIcon>
                      </WhatsappShareButton>
                      <br></br>
                      <br></br>
                      {item.attributes.hdlink && (
                        <a
                          href="https://showflix.shop/archives/3977"
                          style={{ fontSize: "14px", color: "#b8b45a" }}
                        >
                          {" "}
                          👉 How to Download G-drive link?
                        </a>
                      )}
                      <span style={{ paddingLeft: 10 }}>
                        <OutlineButton className="butn" onClick={telegramlink}>
                          {" "}
                          <>
                            {" "}
                            <img
                              style={{
                                maxWidth: 25,
                                maxHeight: 25,
                                borderRadius: 5,
                              }}
                              src={telelogo}
                              alt="Telegram link"
                            />{" "}
                            <span>Join us</span>{" "}
                          </>
                        </OutlineButton>
                      </span>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className="btns">
                        {item.attributes.hdlink && (
                          <OutlineButton className="butn" onClick={hdHandler}>
                            {" "}
                            {hdloading ? (
                              <span>Getting Link...</span>
                            ) : (
                              <>
                                {" "}
                                <img
                                  style={{
                                    maxWidth: 75,
                                    maxHeight: 25,
                                    borderRadius: 5,
                                  }}
                                  src={downimage}
                                  alt="HD Download"
                                />{" "}
                                <span>G - Drive</span>{" "}
                              </>
                            )}
                          </OutlineButton>
                        )}
                        {item.attributes.HubCloud && (
                          <OutlineButton
                            className="butn"
                            onClick={hubcloudHandler}
                          >
                            {" "}
                            {hdloading ? (
                              <span>Getting Link...</span>
                            ) : (
                              <>
                                {" "}
                                <img
                                  style={{
                                    maxWidth: 75,
                                    maxHeight: 25,
                                    borderRadius: 5,
                                  }}
                                  src={downtele}
                                  alt="HD Download"
                                />{" "}
                                <span>HubCloud & Telegram</span>{" "}
                              </>
                            )}
                          </OutlineButton>
                        )}
                      </div>
                      <br></br>
                      <span>Share :</span>
                      <span style={{ paddingLeft: 5 }}> </span>
                      <FacebookShareButton
                        quote={`${item.attributes.seriesName} - ${item.attributes.seriesCategory} 𝕊𝕖𝕣𝕚𝕖𝕤 - 𝐒𝐇𝐎𝐖𝐅𝐋𝐈𝐗`}
                        url={`${window.location.href}`}
                      >
                        <FacebookIcon size={36} borderRadius={10} />
                      </FacebookShareButton>
                      <span style={{ paddingLeft: 5 }}> </span>
                      <TelegramShareButton
                        title={`${item.attributes.seriesName} - ${item.attributes.seriesCategory} 𝕊𝕖𝕣𝕚𝕖𝕤 - 𝐒𝐇𝐎𝐖𝐅𝐋𝐈𝐗`}
                        url={`${window.location.href}`}
                      >
                        <TelegramIcon size={36} borderRadius={10} />
                      </TelegramShareButton>
                      <span style={{ paddingLeft: 5 }}> </span>
                      <TwitterShareButton
                        title={`${item.attributes.seriesName} - ${item.attributes.seriesCategory} 𝕊𝕖𝕣𝕚𝕖𝕤 - 𝐒𝐇𝐎𝐖𝐅𝐋𝐈𝐗`}
                        url={`${window.location.href}`}
                      >
                        <TwitterIcon size={36} borderRadius={10}></TwitterIcon>
                      </TwitterShareButton>
                      <span style={{ paddingLeft: 5 }}> </span>
                      <WhatsappShareButton
                        title={`${item.attributes.seriesName} - ${item.attributes.seriesCategory} 𝕊𝕖𝕣𝕚𝕖𝕤 - 𝐒𝐇𝐎𝐖𝐅𝐋𝐈𝐗`}
                        url={`${window.location.href}`}
                        separator=":: "
                      >
                        <WhatsappIcon
                          size={36}
                          borderRadius={10}
                        ></WhatsappIcon>
                      </WhatsappShareButton>
                      <br></br>
                      {item.attributes.hdlink && (
                        <a
                          href="https://shrinkus.tk/archives/3977"
                          style={{ fontSize: "14px", color: "#b8b45a" }}
                        >
                          {" "}
                          👉 How to Download G-drive link?
                        </a>
                      )}
                      <span style={{ paddingLeft: 10 }}>
                        <OutlineButton className="butn" onClick={telegramlink}>
                          {" "}
                          <>
                            {" "}
                            <img
                              style={{
                                maxWidth: 25,
                                maxHeight: 25,
                                borderRadius: 5,
                              }}
                              src={telelogo}
                              alt="Telegram link"
                            />{" "}
                            <span>Join us</span>{" "}
                          </>
                        </OutlineButton>
                      </span>
                    </>
                  )}

                  {/* <Adcomp id='343745'></Adcomp>mobile */}
                </div>
              </div>
              {/* <iframe data-aa='2065434' src='//acceptable.a-ads.com/2065434' style={{border:'0px', padding:0, width:'100%' ,height:'100%', overflow:"hidden", backgroundColor: "transparent"}}></iframe> */}
              <div>
                {category !== "movie" &&
                  Object.keys(season).map((item, i) => (
                    <div key={i}>
                      <Seasons
                        items={season}
                        item={item}
                        hditems={hdseason}
                        streamwishitems={streamwishseason}
                        streamrubyitems={streamrubyseason}
                        filelionsitems={filelionsseason}
                        id={i}
                        setCountDp={setCountDp}
                        setUrlCount={setUrlCount}
                        setupnShare={setupnShare}
                        setstreamwishurl={setstreamwishurl}
                        setstreamrubyurl={setstreamrubyurl}
                        setfilelionsurl={setfilelionsurl}
                      ></Seasons>
                    </div>
                  ))}
              </div>

              {/* <BrowserView> <Banner1></Banner1></BrowserView> */}
              <div className="container">
                <br></br>
                {/* <script src="https://jsc.adskeeper.co.uk/s/h/showflix.in.1396580.js" async="async">
                  </script> */}
                {/* <div id="M850995ScriptRootC1396580">
                  </div> */}
                <br></br>
                {/* <div className="section mb-3">
                                <VideoList id={item.id}/>
                            </div> */}
                {/* <Adcomp id='343746'></Adcomp>mobile */}

                <div className="section mb-3">
                  <div className="section__header mb-2">
                    <h2>Similar</h2>
                  </div>
                  <MovieList
                    category={category}
                    type={
                      item.attributes.category || item.attributes.seriesCategory
                    }
                    id={item.id}
                  />
                </div>
              </div>
              {/* <BrowserView> <Banner></Banner></BrowserView>
          <MobileView><Banner></Banner></MobileView> */}

              {/* <MobileView><Banner1></Banner1></MobileView> */}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "700px",
                width: "100%",
              }}
            >
              <div>
                <PropagateLoader color="#09dfeb" loading="true" size={15} />
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <CountDownPage
            url={urlCount}
            hideurl={hideurl}
            aditems={aditems}
            uploadEverurl={uploadEverurl}
            streamwishurl={streamwishurl}
            streamrubyurl={streamrubyurl}
            filelionsurl={filelionsurl}
            hubcloudurl={hubcloud}
            upnShareurl={upnShare}
          ></CountDownPage>
        </>
      )}
    </>
  );
};

export default Detail;

import React, { useState, useEffect,Suspense } from 'react';
import PropTypes from 'prop-types';
import PropagateLoader from "react-spinners/PropagateLoader";
import './movie-list.scss';

import { SwiperSlide, Swiper } from 'swiper/react';
import { Link } from 'react-router-dom';
import loadmore from '../../assets/iconmore.jpg'
import Button from '../button/Button';


const MovieCard=React.lazy(()=>import('../movie-card/MovieCard'));
const Parse = require('parse'); 
const MovieList = props => {

    const [items, setItems] = useState([]);

    useEffect(() => {
        const getlatList = async () => {
            var classQuery;
            try {
                if(props.category==='movie')
                {
                     classQuery='latestMovies';
                }
                else{
                     classQuery='series';
                }
                const Movies = Parse.Object.extend(classQuery);
                const query = new Parse.Query(Movies);
                query.limit(50);
                if(props.category==='movie')
                {
                    query.descending("createdAt");
                }
                else{
                    query.descending("updatedAt");
                }
                const results=(await query.find());
                
                //  console.log(results);
                 setItems(results);
            } catch {
                console.log('error');
            }
        }
        const getoscarsList = async () => {
            var classQuery;
            try {
                if(props.category==='movie')
                {
                     classQuery='movies';
                }
                else{
                     classQuery='series';
                }
                const Movies = Parse.Object.extend(classQuery);
                const query = new Parse.Query(Movies);
                
                if(props.category==='movie')
                {
                    query.equalTo("language", "osc");
                    query.descending("updatedAt");
                }
                else{
                    query.descending("updatedAt");
                }
                query.limit(50);
                const results=(await query.find());
                
                //  console.log(results);
                 setItems(results);
            } catch {
                console.log('error');
            }
        }
        const getmcuList = async () => {
            var classQuery;
            try {
                if(props.category==='movie')
                {
                     classQuery='movies';
                }
                else{
                     classQuery='series';
                }
                const Movies = Parse.Object.extend(classQuery);
                const query = new Parse.Query(Movies);
                
                if(props.category==='movie')
                {
                    query.equalTo("language", "mcu");
                    query.descending("updatedAt");
                }
                else{
                    query.descending("updatedAt");
                }
                query.limit(50);
                const results=(await query.find());
                
                //  console.log(results);
                 setItems(results);
            } catch {
                console.log('error');
            }
        }
        const gettopList = async () => {
            var classQuery;
            var Queryval;
            try {
                if(props.category==='movie')
                {
                     classQuery='movies';
                     Queryval='updatedAt';
                }
                else{
                     classQuery='series';
                     Queryval='seriesRating'
                }
                const Movies = Parse.Object.extend(classQuery);
                const query = new Parse.Query(Movies);
                query.descending(Queryval);
                query.limit(50);
                const results=(await query.find());
                // console.log(results);
                setItems(results);
            } catch {
                console.log('error');
            }
        }
        const gettamList = async () => {
            var classQuery;
            var Queryval;
            try {
                if(props.category==='movie')
                {
                     classQuery='movies';
                     Queryval='category';
                }
                else{
                     classQuery='series';
                     Queryval='seriesCategory'
                }
                const Movies = Parse.Object.extend(classQuery);
                const query = new Parse.Query(Movies);
                query.contains(Queryval,"Tamil");
                 query.descending("createdAt");
                query.limit(50);
               
                const results=(await query.find());
                var filtered = results.filter(function(value, index, arr){
                    if(props.category==='movie')
                    var cat=value.attributes.category;
                    else
                    var cat=value.attributes.seriesCategory;
                    return !cat.includes("Tamil Dubbed");
                });
                const shuffled = filtered.sort(() => Math.random() - 0.5)
                // console.log(results);
                 setItems(filtered);
                // console.log(filtered);
               
            } catch {
                console.log('error');
            }
        }
        const getengList = async () => {
            var classQuery;
            var Queryval;
            
            try {
                if(props.category==='movie')
                {
                     classQuery='movies';
                     Queryval='category';
                }
                else{
                     classQuery='series';
                     Queryval='seriesCategory'
                }
                const Movies = Parse.Object.extend(classQuery);
                const query = new Parse.Query(Movies);
                query.contains(Queryval,"English");
                query.limit(30);
                query.descending("createdAt");
                const results=(await query.find());
                // console.log(results);
                const shuffled = results.sort(() => Math.random() - 0.5)
                // console.log(results);
                 setItems(shuffled);
            } catch {
                console.log('error');
            }
        }
        const gettelList = async () => {
            var classQuery;
            var Queryval;
            
            try {
                if(props.category==='movie')
                {
                     classQuery='movies';
                     Queryval='category';
                }
                else{
                     classQuery='series';
                     Queryval='seriesCategory'
                }
                const Movies = Parse.Object.extend(classQuery);
                const query = new Parse.Query(Movies);
                query.contains(Queryval,"Telugu");
                query.limit(30);
                query.descending("createdAt");
                const results=(await query.find());
                // console.log(results);
                const shuffled = results.sort(() => Math.random() - 0.5)
                // console.log(results);
                 setItems(shuffled);
            } catch {
                console.log('error');
            }
        }
        const gethinList = async () => {
            var classQuery;
            var Queryval;
            try {
                if(props.category==='movie')
                {
                     classQuery='movies';
                     Queryval='category';
                }
                else{
                     classQuery='series';
                     Queryval='seriesCategory'
                }
                const Movies = Parse.Object.extend(classQuery);
                const query = new Parse.Query(Movies);
                query.contains(Queryval,"Hindi");
                query.limit(30);
                query.descending("createdAt");
                const results=(await query.find());
                // console.log(results);
                const shuffled = results.sort(() => Math.random() - 0.5)
                // console.log(results);
                 setItems(shuffled);
            } catch {
                console.log('error');
            }
        }
        const getmalList = async () => {
            var classQuery;
            var Queryval;
            try {
                if(props.category==='movie')
                {
                     classQuery='movies';
                     Queryval='category';
                }
                else{
                     classQuery='series';
                     Queryval='seriesCategory'
                }
                const Movies = Parse.Object.extend(classQuery);
                const query = new Parse.Query(Movies);
                query.contains(Queryval,"Malayalam");
                query.limit(30);
                query.descending("createdAt");
                const results=(await query.find());
                // console.log(results);
                const shuffled = results.sort(() => Math.random() - 0.5)
                // console.log(results);
                 setItems(shuffled);
            } catch {
                console.log('error');
            }
        }
        const gettamdubList = async () => {
            var classQuery;
            var Queryval;
            try {
                if(props.category==='movie')
                {
                     classQuery='movies';
                     Queryval='category';
                }
                else{
                     classQuery='series';
                     Queryval='seriesCategory'
                }
                const Movies = Parse.Object.extend(classQuery);
                const query = new Parse.Query(Movies);
                query.contains(Queryval,"Tamil Dubbed");
                query.limit(30);
                query.descending("createdAt");
                const results=(await query.find());
                 //console.log(results);
                const shuffled = results.sort(() => Math.random() - 0.5)
                // console.log(results);
                 setItems(shuffled);
            } catch {
                console.log('error');
            }
        }
       
        if(props.type === "latest"){
            getlatList();
        }
        else if(props.type === "toprated"){
            gettopList();
        }
        else if(props.type === "Marvel"){
            getmcuList();
        }
        else if(props.type === "Oscars"){
            getoscarsList();
        }
        else if(props.type.includes("Tamil Dubbed")){
            gettamdubList();
        }
        else if(props.type.includes("English")){
            getengList();
        }
        else if(props.type.includes("Tamil")){
            gettamList();
        }
        else if(props.type.includes("Telugu")){
            gettelList();
        }
        else if(props.type.includes("Hindi")){
            gethinList();
        }
        else if(props.type.includes("Malayalam")){
            getmalList();
        }
    }, []);

    return (
        <>
        { items.length ? 
        <div className="movie-list">
            <Swiper
                grabCursor={true}
                spaceBetween={10}
                slidesPerView={'auto'}
            >
                {
                    items.map((item, i) => (
                        <SwiperSlide key={i}>
                             <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                            <MovieCard item={item} category={props.category}/></Suspense>
                        </SwiperSlide>
                    ))
                }
                     <SwiperSlide>
                     <Link to={props.type!=="latest"&&props.type!=="toprated"?`lan/${props.category}/${props.type}`:`${props.category}`}>
                     <img style={{paddingTop:"30%"}} src={loadmore}></img>
                        </Link>
                    
                    </SwiperSlide>
            </Swiper>
        </div>:<div style={{display: 'flex',
                justifyContent:'center',
                alignItems: 'center',
                width: '100%',
                }}><div 
                ><PropagateLoader color='#09dfeb' loading='true' size={15}/></div></div>}
        </>
    );
}

MovieList.propTypes = {
    category: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
}

export default MovieList;

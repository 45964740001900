import React from 'react';
import './Adcomp.css'
export default class Adcomp extends React.Component {
  componentDidMount () {
    (window.adsurfebe = window.adsurfebe || []).push({});
  }

render () {
    return (
      <div className='addiv'>
      <ins className="surfe-be" data-sid={this.props.id}></ins> </div>
    );
  }
}
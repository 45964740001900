import React from "react";
import "./countDownPageStyle.css";
import { useRef, useState } from "react";
import axios from "axios";
import downtele from "../../assets/teleim.gif";
import { Helmet } from "react-helmet";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Button, { OutlineButton } from "../../components/button/Button";
import { useEffect } from "react";
import { PropagateLoader } from "react-spinners";
import Downloaderror from "./Downloaderror";
import downimg from "../../assets/down.gif";
const Parse = require("parse");
const Banner = () => {
  const banner = useRef(HTMLDivElement);
  useEffect(() => {
    if (!banner.current.firstChild) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://glimtors.net/pfe/current/tag.min.js?z=5636844`;
      script["data-cfasync"] = false;
      script.async = true;
      if (banner.current) {
        banner.current.append(script);
      }
    }
  });
  return (
    <>
      <div ref={banner}></div>
      <div id=""></div>
    </>
  );
};
const BannerAdsterraNative = () => {
  const bannerAdsterraNative = useRef(HTMLDivElement);
  useEffect(() => {
    if (!bannerAdsterraNative.current.firstChild) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `//pl18351752.highcpmrevenuenetwork.com/c1104908baa164653cf711c316ac673d/invoke.js`;
      script["data-cfasync"] = false;
      script.async = true;
      if (bannerAdsterraNative.current) {
        bannerAdsterraNative.current.append(script);
      }
    }
  });
  return (
    <>
      <div ref={bannerAdsterraNative}></div>
      <div id="container-c1104908baa164653cf711c316ac673d"></div>
    </>
  );
};
const BannerAdsterra = () => {
  const bannerAdsterra = useRef(HTMLDivElement);
  const atOptions = {
    key: "a776775ed6e7c16a6bd9c2ae77a2b55e",
    format: "iframe",
    height: 250,
    width: 300,
    params: {},
  };
  useEffect(() => {
    if (!bannerAdsterra.current.firstChild) {
      const conf = document.createElement("script");
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `//www.effectivecreativeformat.com/a776775ed6e7c16a6bd9c2ae77a2b55e/invoke.js`;
      conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

      if (bannerAdsterra.current) {
        bannerAdsterra.current.append(conf);
        bannerAdsterra.current.append(script);
      }
    }
  });
  return (
    <>
      <div ref={bannerAdsterra}></div>
    </>
  );
};
const BannerPopUnder = (s, u, z, p) => {
  const bannerpopunder = useRef(HTMLDivElement);
  useEffect(() => {
    if (!bannerpopunder.current.firstChild) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://arvigorothan.com/tag.min.js`;
      script.async = true;
      // script["data-zone"] = 5636232;
      script.setAttribute("data-zone", 7635896);
      if (bannerpopunder.current) {
        bannerpopunder.current.append(script);
      }
    }
  });
  return (
    <>
      <div ref={bannerpopunder}></div>
      <div id="7635896"></div>
    </>
  );
};
const BannerInt = () => {
  const bannerint = useRef(HTMLDivElement);
  useEffect(() => {
    if (!bannerint.current.firstChild) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `//thubanoa.com/1?z=7635953`;
      script["data-cfasync"] = false;
      script.async = true;
      if (bannerint.current) {
        bannerint.current.append(script);
      }
    }
  });
  return (
    <>
      <div ref={bannerint}></div>
      <div id=""></div>
    </>
  );
};
const BannerStudAds = () => {
  const bannerstudads = useRef(HTMLDivElement);
  useEffect(() => {
    if (!bannerstudads.current.firstChild) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `//servedby.studads.com/ads/ads.php?t=MTgwODM7MTE5NDg7c3F1YXJlLnNxdWFyZV9ib3g=&index=1`;
      script["data-cfasync"] = true;
      script.async = true;
      if (bannerstudads.current) {
        bannerstudads.current.append(script);
      }
    }
  });
  return (
    <>
      <div ref={bannerstudads}></div>
      <div id=""></div>
    </>
  );
};
const BannerStudAds1 = () => {
  const bannerstudads1 = useRef(HTMLDivElement);
  useEffect(() => {
    if (!bannerstudads1.current.firstChild) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://media.dalysv.com/js/code.min.js`;
      script["data-cfasync"] = false;
      script.async = true;
      if (bannerstudads1.current) {
        bannerstudads1.current.append(script);
      }
    }
  });
  return (
    <>
      <div ref={bannerstudads1}></div>
      <div data-zone="100207"></div>
    </>
  );
};
const BannerVintage = () => {
  const bannervintage = useRef(HTMLDivElement);
  useEffect(() => {
    if (!bannervintage.current.firstChild) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://glizauvo.net/401/7635797`;
      script.async = true;
      script.setAttribute("data-zone", 7635797);

      if (bannervintage.current) {
        bannervintage.current.append(script);
      }
    }
  });
  return (
    <>
      <div ref={bannervintage}></div>
      <div id="7635797"></div>
    </>
  );
};
const RenderTime = ({ remainingTime }) => {
  const currentTime = useRef(remainingTime);
  const prevTime = useRef(null);
  const isNewTimeFirstTick = useRef(false);
  const [, setOneLastRerender] = useState(0);

  if (currentTime.current !== remainingTime) {
    isNewTimeFirstTick.current = true;
    prevTime.current = currentTime.current;
    currentTime.current = remainingTime;
  } else {
    isNewTimeFirstTick.current = false;
  }

  // force one last re-render when the time is over to tirgger the last animation
  if (remainingTime === 0) {
    setTimeout(() => {
      setOneLastRerender((val) => val + 1);
    }, 20);
  }

  const isTimeUp = isNewTimeFirstTick.current;

  return (
    <div className="time-wrapper">
      <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
        {remainingTime}
      </div>
      {prevTime.current !== null && (
        <div
          key={prevTime.current}
          className={`time ${!isTimeUp ? "down" : ""}`}
        >
          {prevTime.current}
        </div>
      )}
    </div>
  );
};
const CountDownPage = ({
  url,
  hideurl,
  aditems,
  uploadEverurl,
  streamwishurl,
  streamrubyurl,
  filelionsurl,
  hubcloudurl,
  upnShareurl
}) => {
  const Duration = 5;
  const [isTimerFinished, setIsTimerFinished] = useState(false);
  const num = Math.floor(Math.random() * aditems.length);
  const num1 = Math.floor(Math.random() * aditems.length);
  const [downloaderror, setDownloaderror] = useState(false);

  // useEffect(async () => {
  //   if (num === num1) {
  //     const viewcount = aditems[num].attributes.views + 1;
  //     const GameScore = Parse.Object.extend("Ads");
  //     const query = new Parse.Query(GameScore);
  //     //console.log(aditems[num].id);
  //     query.equalTo("objectId", aditems[num].id);
  //     const queryitem = await query.find();
  //     queryitem[0].set("views", viewcount);
  //     queryitem[0].save().then((result) => console.log());
  //   } else {
  //     const viewcount = aditems[num].attributes.views + 1;
  //     const GameScore = Parse.Object.extend("Ads");
  //     const query = new Parse.Query(GameScore);
  //     //console.log(aditems[num].id);
  //     query.equalTo("objectId", aditems[num].id);
  //     const queryitem = await query.find();
  //     queryitem[0].set("views", viewcount);
  //     queryitem[0].save().then((result) => console.log());

  //     const viewcount1 = aditems[num1].attributes.views + 1;
  //     const GameScore1 = Parse.Object.extend("Ads");
  //     const query1 = new Parse.Query(GameScore1);
  //     //console.log(aditems[num1].id);
  //     query1.equalTo("objectId", aditems[num1].id);
  //     const queryitem1 = await query1.find();
  //     queryitem1[0].set("views", viewcount1);
  //     queryitem1[0].save().then((result) => console.log());
  //   }

  //   // await query.find({
  //   //     success:function(results){
  //   //         const viewcount=aditems[num].attributes.views+1;
  //   //         console.log(results)
  //   //         results.set('views',viewcount)
  //   //         //results.save();
  //   //     },error:function(error){
  //   //         console.log(error)
  //   //     }
  //   // })
  //   // each of results will only have the selected fields available.
  // }, []);

  const downloadButn = () => {
    window.open(url, "_blank")?.focus();
  };
  const downloadButnever = () => {
    window.open(`https://uploadever.in/${uploadEverurl}`, "_blank")?.focus();
  };
  const downloadButnstreamwish = () => {
    window.open(`https:///swdyu.com/f/${streamwishurl}`, "_blank")?.focus();
  };
  const downloadButnstreamruby = () => {
    window
      .open(`https://showflix.beauty/d/${streamrubyurl}`, "_blank")
      ?.focus();
  };
  const downloadButnfilelions = () => {
    window.open(`https://vidhidepro.com/d/${filelionsurl}`, "_blank")?.focus();
  };
  const downloadButnupnShare = () => {
    window.open(`https://sf.upns.xyz/#${upnShareurl}&dl=1`, "_blank")?.focus();
  };
  const hubcloudHandler = () => {
    window.open(`${hubcloudurl}`, "_blank")?.focus();
  };
  const hideDownHandler = async () => {
    // const url2 = `https://us-central1-affiliate2apk.cloudfunctions.net/get_data?shortid=${apilink}`
    // await axios.get(url2).then((response) =>{
    // //  console.log(response)
    //   filemon=response.data.result.filecode;
    // }).catch((error) => console.error(error))
  };
  return (
    <>
      {/* <BannerInt></BannerInt> monetag ads interstitial */}
      <BannerInt></BannerInt>
      <BannerVintage></BannerVintage>
      {downloaderror ? (
        <Downloaderror></Downloaderror>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "900px",
              width: "100%",
              flexDirection: "column",
            }}
          >
            {/* <div style={{backgroundImage:`url(${aditems[num].attributes.adimage})`,height:'200px',width:'320px',borderColor:'Highlight',
        borderRadius:'5px',borderWidth:'2px',borderStyle:'solid',textAlign:'center',color:'gray',padding:'3px',cursor:'pointer'
        }} onClick={async ()=>{ if(aditems[num].attributes.link==="null"){window.open("https://telegram.me/showflixadmin", "_blank")?.focus();} else {
            const viewcount=aditems[num].attributes.click+1;
            const GameScore = Parse.Object.extend("Ads");
            const query = new Parse.Query(GameScore);
            console.log(aditems[num].id);
            query.equalTo("objectId",aditems[num].id);
            const queryitem=await query.find();
            queryitem[0].set('click',viewcount);
            queryitem[0].save().then((result)=>console.log(result));
             window.open(aditems[num].attributes.link, "_blank")?.focus();}}}>
                {aditems[num].attributes.adimage==="null" && <>your ads will be here contact telegram: @showflixadmin</>}</div> */}
            <BannerPopUnder></BannerPopUnder> {/*monetag*/}
            {/* <BannerStudAds1></BannerStudAds1> */}
            {/* <iframe
              src="https://media.dalysv.com/js/ifr.html#id=97654"
              style={{
                width: "300px",
                height: "250px",
                overflow: "hidden",
                border: "none",
                align: "center",
                frameborder: "0",
              }}
            ></iframe>{" "} */}
            {/*click Daly ads*/}
            {/* <BannerAdsterraNative></BannerAdsterraNative> */}
            <br></br>
            {!isTimerFinished && (
              <div className="timer-wrapper">
                <CountdownCircleTimer
                  onComplete={() => {
                    setIsTimerFinished(true);
                  }}
                  isPlaying
                  duration={Duration}
                  colors={["#7FFFD4", "#40E0D0", "#00FFFF", "#E0FFFF"]}
                  colorsTime={[5, 3, 2, 0]}
                >
                  {RenderTime}
                </CountdownCircleTimer>
              </div>
            )}
            <br></br>
            {!isTimerFinished && <h1>Please Wait...</h1>}
            {isTimerFinished && (
              <>
                <div
                  style={{
                    textAlign: "center",
                    marginLeft: "25px",
                    marginRight: "25px",
                    marginBottom: "25px",
                    color: "black",
                  }}
                >
                  <div>
                    {hubcloudurl && (
                      <OutlineButton className="butn" onClick={hubcloudHandler}>
                        {" "}
                        {
                          <>
                            {" "}
                            <img
                              style={{
                                maxWidth: 75,
                                maxHeight: 25,
                                borderRadius: 5,
                              }}
                              src={downtele}
                              alt="HD Download"
                            />{" "}
                            <span>Download HubCloud & Telegram</span>{" "}
                          </>
                        }
                      </OutlineButton>
                    )}
                    <br></br>
                  </div>
                  {upnShareurl && (
                    <Button onClick={downloadButnupnShare}>
                      <img
                        style={{ maxWidth: 75, maxHeight: 25, borderRadius: 5 }}
                        src={downimg}
                        alt="DownloadupnShare"
                      />{" "}
                      {"Download FHD Quality"}{" "}
                    </Button>
                  )}
                  {streamrubyurl && (
                    <Button onClick={downloadButnstreamruby}>
                      <img
                        style={{ maxWidth: 75, maxHeight: 25, borderRadius: 5 }}
                        src={downimg}
                        alt="Downloadever"
                      />{" "}
                      {"Download 1080p/720p/360p"}{" "}
                    </Button>
                  )}
                  {url && (
                    <Button onClick={downloadButn}>
                      <img
                        style={{ maxWidth: 75, maxHeight: 25, borderRadius: 5 }}
                        src={downimg}
                        alt="Download"
                      />{" "}
                      {uploadEverurl || streamwishurl || streamrubyurl
                        ? "Download 720/480p"
                        : "Click here to Download"}{" "}
                    </Button>
                  )}
                  {filelionsurl && (
                    <Button onClick={downloadButnfilelions}>
                      <img
                        style={{ maxWidth: 75, maxHeight: 25, borderRadius: 5 }}
                        src={downimg}
                        alt="Download"
                      />{" "}
                      {uploadEverurl || streamwishurl || streamrubyurl
                        ? "Download 720/480p"
                        : "Click here to Download"}{" "}
                    </Button>
                  )}

                  {streamwishurl && (
                    <Button onClick={downloadButnstreamwish}>
                      <img
                        style={{ maxWidth: 75, maxHeight: 25, borderRadius: 5 }}
                        src={downimg}
                        alt="Downloadever"
                      />{" "}
                      {"Download 1080p"}{" "}
                    </Button>
                  )}
                  {uploadEverurl && (
                    <Button onClick={downloadButnever}>
                      <img
                        style={{ maxWidth: 75, maxHeight: 25, borderRadius: 5 }}
                        src={downimg}
                        alt="Downloadever"
                      />{" "}
                      {"Download 1080p (UploadEver) "}{" "}
                    </Button>
                  )}
                  {!url &&
                    !uploadEverurl &&
                    !streamwishurl &&
                    !streamrubyurl && (
                      <h1 style={{ color: "red" }}>
                        Sorry no download link Available
                      </h1>
                    )}
                  {!url &&
                    !uploadEverurl &&
                    !streamwishurl &&
                    !streamrubyurl && (
                      <h3 style={{ color: "grey" }}>
                        we will fix it soon. Please visit us again
                      </h3>
                    )}
                </div>
                <br></br>
                <br></br>
                {uploadEverurl ? (
                  <div>
                    <p
                      style={{
                        cursor: "pointer",
                        color: "GrayText",
                        fontWeight: "bold",
                      }}
                      onClick={() =>
                        window
                          .open(`https://shrinkus.tk/archives/5144`, "_blank")
                          ?.focus()
                      }
                    >
                      How to Download 1080p link?
                    </p>
                  </div>
                ) : (
                  <div>
                    {url !== null &&
                      streamwishurl !== null &&
                      streamrubyurl !== null && (
                        <p
                          style={{
                            cursor: "pointer",
                            color: "GrayText",
                            fontWeight: "bold",
                          }}
                          onClick={() => setDownloaderror(true)}
                        >
                          Not Able to Download?
                        </p>
                      )}
                  </div>
                )}
              </>
            )}
            {/* {hideurl&&<div><Button onClick={hideDownHandler}><img style={{maxWidth:75,maxHeight:25}} src={downimg} alt="Play" /> Download 1080p</Button></div>} */}
            <br></br>
            {/* <Banner></Banner> monetag ads */}
            {/* <iframe
              src="https://media.dalysv.com/js/ifr.html#id=100207"
              style={{
                width: "300px",
                height: "250px",
                overflow: "hidden",
                border: "none",
                align: "center",
                frameborder: "0",
              }}
            ></iframe> */}
            {/*click Daly ads*/}
            {/* <BannerStudAds></BannerStudAds> */}
            {/* <BannerAdsterra></BannerAdsterra> */}
            {/* <script src="https://jsc.adskeeper.co.uk/s/h/showflix.in.1396580.js" async>
        </script> */}
            {/* <!-- Composite Start --> */}
            {/* <script src="https://jsc.adskeeper.co.uk/s/h/showflix.in.1396347.js" async>
        </script>
        <div id="M850995ScriptRootC1396347">
        </div> */}
            {/* <!-- Composite End --> */}
            {/* <div style={{backgroundImage:`url(${aditems[num1].attributes.adimage})`,height:'200px',width:'320px',borderColor:'Highlight',borderRadius:'5px',
  borderWidth:'2px',borderStyle:'solid',textAlign:'center',color:'gray',padding:'3px',cursor:'pointer'}}
  onClick={async ()=>{if(aditems[num1].attributes.link==="null"){window.open("https://telegram.me/showflixadmin", "_blank")?.focus();} else {
    const viewcount1=aditems[num1].attributes.click+1;
    const GameScore1 = Parse.Object.extend("Ads");
    const query1 = new Parse.Query(GameScore1);
    console.log(aditems[num1].id);
    query1.equalTo("objectId",aditems[num1].id);
    const queryitem1=await query1.find();
    queryitem1[0].set('click',viewcount1);
    queryitem1[0].save().then((result)=>console.log(result));  
  window.open(aditems[num1].attributes.link, "_blank")?.focus();}}}
  >{aditems[num1].attributes.adimage==="null" && <>your ads will be here contact telegram: @showflixadmin</>}</div> */}
          </div>
        </>
      )}
    </>
  );
};

export default CountDownPage;

import 'swiper/swiper.min.css';

import './assets/boxicons-2.0.7/css/boxicons.min.css';
import './App.scss';
import { useEffect,useState } from 'react';
import Button from './components/button/Button';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { OutlineButton } from './components/button/Button';
import { BrowserRouter, Route } from 'react-router-dom';
import { useDetectAdBlock } from "adblock-detect-react";
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import { Helmet } from "react-helmet";
import Routes from './config/Routes';
import SignIn from './pages/SignIn';
import ScrollToTop from './ScrollToTop';
const Parse = require('parse');
Parse.initialize("SHOWFLIXAPPID","SHOWFLIXMASTERKEY");
//javascriptKey is required only if you have it on server.

Parse.serverURL = 'https://parse.showflix.shop/parse'
function App() {
    disableReactDevTools();
    const adBlockDetected = useDetectAdBlock();
    const [isLoggedin,setIsLoggedIn]=useState(false);
    useEffect(() => {
        if (adBlockDetected) {
          window.alert("ad block detected");
        }
      }, []
      );
      useEffect(()=>{ console.clear();});
     
    return (
        <BrowserRouter>
            <Route render={props => (
                <>
        {/* {adBlockDetected ? <div style={{display: "flex",justifyContent:"center",alignItems: 'center',border: '5px solid aqua',backgroundColor:'#3a5957',color:'white',padding:'5%',top:'35%',bottom:'35%',left:'15%',right:'15%',position:'absolute'}} >
                <div >

                    <div style={{display: "flex",justifyContent:"center",textAlign:'center'}}>
                        <img style={{width:'10%',height:'10%'}}  src="https://shrinkus.tk/wp-content/plugins/chp-ads-block-detector/assets/img/icon.png" alt="Ads Blocker Image "/></div>

                    <h4 style={{textAlign:"center"}}>Ads Blocker Detected!!!</h4>
                    <br></br>
                    <div style={{textAlign:"center"}}><p>We have detected that you are using extensions to block ads. Please support us by disabling these ads blocker and refresh.</p></div>
                    <br></br>

                    <div style={{textAlign:"center"}}>
                        <Button  onClick={()=>window.location.reload(false)}>Refresh</Button>
                        <OutlineButton className='btnn-outline' onClick={()=>window.open("https://vimeo.com/741102420", "_blank")?.focus()}> Unblock Ads </OutlineButton>
                         </div>
                </div>
            </div>:} */}
           
            <>  <ScrollToTop />
                <Header {...props}/>
                    <Routes/>
                    <Footer/></>
                   
                </>
            )}/>
        </BrowserRouter>
    );
}

export default App;

import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router";
import PropagateLoader from "react-spinners/PropagateLoader";
import "./movie-grid.scss";
import Select from "react-select";
import MovieCard from "../movie-card/MovieCard";
import Button, { OutlineButton } from "../button/Button";
import Input from "../input/Input";
import Adcomp from "../../ads/Adcomp";
import NotFound from "../../assets/notfound.gif";
import { useDebounce } from "use-debounce";
import { yellow } from "@mui/material/colors";

const Parse = require("parse");
const MovieGrid = (props) => {
  const [category, setCategory] = useState(props.category);
  const options = [
    { value: "All", label: "All Language" },
    { value: "Tamil", label: "Tamil" },
    { value: "Telugu", label: "Telugu" },
    { value: "Tamil Dubbed", label: "Tamil Dubbed" },
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" },
    { value: "Malayalam", label: "Malayalam" },
  ];
  const optionsc = [
    { value: "all", label: "All" },
    { value: "movie", label: "Movies" },
    { value: "series", label: "Series" },
  ];
  const [items, setItems] = useState([]);
  const [seriesItems, setSeriesItems] = useState([]);
  const [type, setType] = useState(props.type);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingmore, setLoadingMore] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [totalmvpage,settotalmvpage]=useState(0);
//   var displayLimit = 20;
//   if (props.category != "all") {
//     displayLimit = 50;
//   }
  const { keyword } = useParams();
  const [searchTerm, setSearchTerm] = useState(keyword);
  const [searchvalue] = useDebounce(searchTerm, 800);
  const getList = async () => {
    setLoading(true);
    let response1 = null;
    let response2 = null;
    var displayLimit = 20;
    if(category!='all')
    {
        displayLimit = 50;
    }
    if (searchvalue === undefined) {
      // switch(category) {
      //     case "movie":
      //             const Movies = Parse.Object.extend("movies");
      //             const query = new Parse.Query(Movies);
      //             if(type!=='All')
      //              {if(type ==='Marvel')
      //              {

      //                 query.equalTo("language", "mcu");
      //              }
      //              else if(type==='Oscars')
      //              {
      //                 query.equalTo("language", "osc");
      //              }
      //              else {query.contains("category",type);}}
      //             // myCollection.count().then(function(result){ count = result; });
      //              query.limit(displayLimit);
      //              query.descending("updatedAt");
      //              response = await query.find();
      //              const shuffledArray =response; //response.sort((a, b) => 0.5 - Math.random());
      //              setItems(shuffledArray);
      //              setLoading(false);
      //         break;
      //     default:
      //         const Series = Parse.Object.extend("series");
      //         const querys = new Parse.Query(Series);
      //         if(type!=='All')
      //          {querys.contains("seriesCategory",type);}
      //         // myCollection.count().then(function(result){ count = result; });
      //          querys.limit(displayLimit);
      //          querys.descending("updatedAt");
      //          response = await querys.find();
      //          const shuffledArrays =response;// response.sort((a, b) => 0.5 - Math.random());
      //          setItems(shuffledArrays);
      //          setSeriesItems(response);
      //          setLoading(false);
      //         // response = await tmdbApi.getTvList(tvType.popular, {params});
      // }
      const Movies = Parse.Object.extend("movies");
      const query = new Parse.Query(Movies);
      if (type !== "All") {
        if (type === "Marvel") {
          query.equalTo("language", "mcu");
        } else if (type === "Oscars") {
          query.equalTo("language", "osc");
        } else {
          query.contains("category", type);
        }
      }
      // myCollection.count().then(function(result){ count = result; });
      query.limit(displayLimit);
      query.descending("updatedAt");
      query.count().then(function(result){settotalmvpage(Math.ceil(result/displayLimit))});
      response1 = await query.find();
      const shuffledArray = response1; //response.sort((a, b) => 0.5 - Math.random());
      setItems(shuffledArray);

      const Series = Parse.Object.extend("series");
      const querys = new Parse.Query(Series);
      if (type !== "All") {
        querys.contains("seriesCategory", type);
      }
      // myCollection.count().then(function(result){ count = result; });
      querys.limit(displayLimit);
      querys.descending("updatedAt");
      response2 = await querys.find();
      const shuffledArrays = response2; // response.sort((a, b) => 0.5 - Math.random());
      //setItems(shuffledArrays);
      setSeriesItems(response2);
      setLoading(false);
    } else {
      function capitalize(str) {
        var splitStr = str.toLowerCase().split(" ");
        for (var i = 0; i < splitStr.length; i++) {
          // You do not need to check if i is larger than splitStr length, as your for does that for you
          // Assign it back to the array
          splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(" ");
      }
      const Movies = Parse.Object.extend("movies");
      const query = new Parse.Query(Movies);
      query.matches("movieName", searchvalue, "i");
      // query.contains("movieName",capitalize(keyword));
      if (type !== "All") {
        query.contains("category", type);
      }
      query.descending("updatedAt");
      response1 = await query.find();
      setItems(response1);
      if (response1.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
      const Series = Parse.Object.extend("series");
      const querys = new Parse.Query(Series);
      querys.matches("seriesName", searchvalue, "i");
      // querys.contains("seriesName",capitalize(keyword));
      if (type !== "All") {
        querys.contains("seriesCategory", type);
      }
      querys.descending("updatedAt");
      response2 = await querys.find();
      if (response2.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
      setSeriesItems(response2);
      setLoading(false);
      // if(category==='movie'){
      //          const Movies = Parse.Object.extend("movies");
      //         const query = new Parse.Query(Movies);
      //         query.matches("movieName", searchvalue, 'i');
      //         // query.contains("movieName",capitalize(keyword));
      //         if(type!=='All')
      //         {query.contains("category",type);}
      //         query.descending("updatedAt");
      //         response = await query.find();

      //         setItems(response);
      //         if(response.length===0){
      //             setEmpty(true);
      //         }
      //         else{
      //             setEmpty(false);
      //         }
      //         setLoading(false);
      //     }
      //         else{
      //             const Series = Parse.Object.extend("series");
      //             const querys = new Parse.Query(Series);
      //             querys.matches("seriesName", searchvalue, 'i');
      //             // querys.contains("seriesName",capitalize(keyword));
      //             if(type!=='All')
      //             {querys.contains("seriesCategory",type);}
      //             querys.descending("updatedAt");
      //             response = await querys.find();
      //             if(response.length===0){
      //                 setEmpty(true);
      //             }
      //             else{
      //                 setEmpty(false);
      //             }
      //             setItems(response);
      //             setSeriesItems(response);
      //             setLoading(false);
      //         }
    }
    //  setItems(response);
    // setTotalPage(response.total_pages);
  };
  useEffect(() => {
    getList();
  }, [category, keyword, type, searchvalue]);

  const loadMore = async () => {
    let response1 = null;
    let response2 = null;
    var displayLimit = 20;
    if(category!='all')
    {
        displayLimit = 50;
    }
    if (keyword === undefined) {
      setLoadingMore(true);
      const Movies = Parse.Object.extend("movies");
          const query = new Parse.Query(Movies);
          query.descending("updatedAt");
          if (type !== "All") {
            if (type === "Marvel") {
              query.equalTo("language", "mcu");
            } else {
              query.contains("category", type);
            }
          }
          query.limit(displayLimit);
          query.skip(page * displayLimit);
          response1 = await query.find();
          setItems([...items, ...response1]);
          const Series = Parse.Object.extend("series");
          const querys = new Parse.Query(Series);
          querys.descending("updatedAt");
          if (type !== "All") {
            querys.contains("seriesCategory", type);
          }
          querys.limit(displayLimit);
          querys.skip(page * displayLimit);
          response2 = await querys.find();
          setSeriesItems([...seriesItems, ...response2]);
    //   switch (category) {
    //     case "movie":
    //       const Movies = Parse.Object.extend("movies");
    //       const query = new Parse.Query(Movies);
    //       query.descending("updatedAt");
    //       if (type !== "All") {
    //         if (type === "Marvel") {
    //           query.equalTo("language", "mcu");
    //         } else {
    //           query.contains("category", type);
    //         }
    //       }
    //       query.limit(displayLimit);
    //       query.skip(page * displayLimit);
    //       response1 = await query.find();
    //       setItems([...items, ...response1]);
    //       // const shuffledArray = response.sort((a, b) => 0.5 - Math.random());
    //       // response=shuffledArray;
    //       break;
    //     default:
    //       const Series = Parse.Object.extend("series");
    //       const querys = new Parse.Query(Series);
    //       querys.descending("updatedAt");
    //       if (type !== "All") {
    //         querys.contains("seriesCategory", type);
    //       }
    //       querys.limit(displayLimit);
    //       querys.skip(page * displayLimit);
    //       response2 = await querys.find();
    //       setSeriesItems([...seriesItems, ...response2]);
    //       // const shuffledArrays = response.sort((a, b) => 0.5 - Math.random());
    //       // response=shuffledArrays;
    //       break;
    //     // response = await tmdbApi.getTvList(tvType.popular, {params});
    //   }
    } else {
    //   function capitalize(str) {
    //     var splitStr = str.toLowerCase().split(" ");
    //     for (var i = 0; i < splitStr.length; i++) {
    //       // You do not need to check if i is larger than splitStr length, as your for does that for you
    //       // Assign it back to the array
    //       splitStr[i] =
    //         splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    //     }
    //     // Directly return the joined string
    //     return splitStr.join(" ");
    //   }
    const Movies = Parse.Object.extend("movies");
        const query = new Parse.Query(Movies);
        query.matches("movieName", keyword, "i");
        response1 = await query.find();
        setItems([...items, ...response1]);
        const Series = Parse.Object.extend("series");
        const querys = new Parse.Query(Series);
        querys.matches("seriesName", keyword, "i");
        response2 = await querys.find();
        setSeriesItems([...seriesItems, ...response2]);
    //   if (category === "movie") {
    //     const Movies = Parse.Object.extend("movies");
    //     const query = new Parse.Query(Movies);
    //     query.matches("movieName", keyword, "i");
    //     response1 = await query.find();
    //     setItems([...items, ...response1]);
    //   } else {
    //     const Series = Parse.Object.extend("series");
    //     const querys = new Parse.Query(Series);
    //     querys.matches("seriesName", keyword, "i");
    //     response2 = await querys.find();
    //     setSeriesItems([...seriesItems, ...response2]);
    //   }
    }

    setPage(page + 1);
    setLoadingMore(false);
  };
  const setSelectedOption = (value) => {
    setType(value.value);
    setPage(1);
  };
  const setSelectedOptionc = (value) => {
    setCategory(value.value);
    setPage(1);
  };
  return (
    <>
      <div className="section mb-3">
        <MovieSearch
          category={category}
          keyword={keyword}
          setSearchTerm={setSearchTerm}
          getList={getList}
          searchTerm={searchTerm}
        />
      </div>

      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        <h3 style={{ marginRight: "5px", fontFamily: "cursive" }}>
          Choose Category :
        </h3>
        <Select
          isSearchable={false}
          theme={(theme) => ({
            ...theme,
            borderRadius: 10,
            colors: {
              ...theme.colors,
              primary25: "black",
              primary: "black",
              neutral0: "#4bb9de",
              neutral5: "white",
            },
          })}
          defaultValue={optionsc[optionsc.findIndex((optionsc) => optionsc.value === category)]}
          options={optionsc}
          onChange={(value) => {
            setSelectedOptionc(value);
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        <h3 style={{ marginRight: "5px", fontFamily: "cursive" }}>
          Choose Language :
        </h3>
        <Select
          isSearchable={false}
          theme={(theme) => ({
            ...theme,
            borderRadius: 10,
            colors: {
              ...theme.colors,
              primary25: "black",
              primary: "black",
              neutral0: "#4bb9de",
              neutral5: "white",
            },
          })}
          defaultValue={
            options[options.findIndex((options) => options.value === type)]
          }
          options={options}
          onChange={(value) => {
            setSelectedOption(value);
          }}
        />
      </div>
      <br></br>

      {/* <>
        {empty && (
          <img
            src={NotFound}
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "50%",
              maxHeight: "220px",
              maxWidth: "220px",
              borderRadius: "20px",
            }}
          ></img>
        )}
      </> */}
      {/* <>
        {empty && (
          <h1 style={{ textAlign: "center", color: "coral" }}>
            No Match Found...
          </h1>
        )}
        {empty && (
          <h6 style={{ textAlign: "center", color: "#85cc56" }}>
            (Try search in {category === "movie" ? "Series" : "Movies"} by
            changing the category above)
          </h6>
        )}
      </> */}
      <>
        {!loading ? (
          <>
            {category != "series" && (
              <>
            
             {!items.length ? <h1 style={{ textAlign: "center", color: "coral" }}> No Match Found in {type} Movies</h1>:<> {searchvalue ? <h1 style={{ textAlign: "center", color: "lightgreen" }}>Search Result For " {searchvalue} " in {type} Movies</h1>:<h1 style={{ textAlign: "center", color: "#fcba03" }}>{type} Movies</h1>}</>}
                <div className="movie-grid">
                  {items.map((item, i) => (
                    <MovieCard category="movie" item={item} key={i} />
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "20px",
                  }}
                >
                  {loadingmore && (
                    <PropagateLoader color="#09dfeb" loading="true" size={15} />
                  )}
                </div>
                <div className="movie-grid__loadmore">
                  {!empty && items.length > 14 && (
                    <OutlineButton className="small" onClick={loadMore}>
                      Load more ⬇️
                    </OutlineButton>
                  )}
                </div>
              </>
            )}
            <br>
            </br>
            <br></br>
            {category != "movie" && (
              <>
                   {!seriesItems.length ? <h1 style={{ textAlign: "center", color: "coral" }}> No Match Found in {type} Series</h1>:<> {searchvalue ? <h1 style={{ textAlign: "center", color: "lightgreen" }}>Search Result For " {searchvalue} " in {type} Series</h1>:<h1 style={{ textAlign: "center", color: "#fcba03" }}>{type} Series</h1>}</>}
                <div className="movie-grid">
                  {seriesItems.map((item, i) => (
                    <MovieCard category="series" item={item} key={i} />
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "20px",
                  }}
                >
                  {loadingmore && (
                    <PropagateLoader color="#09dfeb" loading="true" size={15} />
                  )}
                </div>
                <div className="movie-grid__loadmore">
                  {!empty && items.length > 14 && (
                    <OutlineButton className="small" onClick={loadMore}>
                      Load more ⬇️
                    </OutlineButton>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              height: "500px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div>
              <PropagateLoader color="#09dfeb" loading="true" size={15} />
            </div>
          </div>
        )}
      </>
    </>
  );
};

const MovieSearch = (props) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(props.keyword ? props.keyword : "");

  const goToSearch = useCallback(() => {
    if (keyword.trim().length > 0) {
      history.push(`/${props.category}/search/${keyword}`);
    }
  }, [keyword, props.category, history]);

  useEffect(() => {
    const enterEvent = (e) => {
      e.preventDefault();
      if (e.keyCode === 13) {
        goToSearch();
      }
    };
    document.addEventListener("keyup", enterEvent);
    return () => {
      document.removeEventListener("keyup", enterEvent);
    };
  }, [keyword, goToSearch]);
  // useEffect(()=>{props.getList()},[searchvalue])

  return (
    <>
      {/* <span style={{display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',fontFamily:'cursive',color:'#fcba03'}}>Enter only First word of movieName (i.e) iron for iron man </span>
            <br></br> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="movie-search">
          <Input
            autoFocus="true"
            type="text"
            placeholder="🔍 movie / series name..."
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
              props.setSearchTerm(e.target.value);
            }}
          />
          <Button className="small" onClick={goToSearch}>
            Search
          </Button>
        </div>
      </div>
    </>
  );
};

export default MovieGrid;

import React,{Suspense} from 'react';
import { Link } from 'react-router-dom';

import { OutlineButton } from '../components/button/Button';

import PropagateLoader from "react-spinners/PropagateLoader";

import Adcomp from '../ads/Adcomp';
import Marquee from 'react-fast-marquee';
const HeroSlide=React.lazy(()=>import('../components/hero-slide/HeroSlide'));
const MovieList=React.lazy(()=>import('../components/movie-list/MovieList'));
const Series = () => {
    return (
        <>
        <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
            <HeroSlide category='series'/>
            </Suspense>
            {/* <Adcomp id='343744'></Adcomp> computer   */}
             {/* <Adcomp id='343745'></Adcomp> mobile */}
             {/* <div >
                <b>
             <Marquee gradientColor={[199, 197, 193]} gradient ={true} gradientWidth={window.innerWidth/8} speed={28} style={{color:'#e7f266',cursor:'pointer',fontSize:'15px'} }>
                 <a href="https://link.showflix.in/androidapp" target="_blank" rel="noopener noreferrer">🔗 Click to get updated Showflix 📱Android App V7.0🔗 </a>
                </Marquee>
                </b>
             </div>
             <br></br>
             <br></br> */}
            <div className="container">

                 {/*Trending Series*/}
                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}}>Trending Series</h2>
                        <Link to="/series">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div>
                    <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"series"} type={"latest"}/></Suspense>
                </div>
                {/* <Adcomp id='343746'></Adcomp>mobile */}

                {/*Top Rated Series*/}
                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}}>Top Rated Series</h2>
                        <Link to="/series">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div>
                    <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"series"} type={"toprated"}/></Suspense>
                </div>
                {/* <Adcomp id='343747'></Adcomp>mobile */}

                {/*Tamil Series*/}
                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}}>Tamil Series</h2>
                        <Link to="/lan/series/Tamil">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div>
                    <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"series"} type={"Tamil"}/></Suspense>
                </div>
                {/* <Adcomp id='343748'></Adcomp>mobile */}

                {/*Tamil Dubbed Series*/}
                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}}>Tamil Dubbed Series</h2>
                        <Link to="/lan/series/Tamil Dubbed">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div> <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"series"} type={"Tamil Dubbed"}/></Suspense>
                </div>
                {/* <Adcomp id='343749'></Adcomp>mobile */}

                {/*English Series*/}
                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}}>English Series</h2>
                        <Link to="/lan/series/English">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div> <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"series"} type={"English"}/></Suspense>
                </div>
                {/* <Adcomp id='343750'></Adcomp>mobile */}

                {/*Telugu Series*/}
                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}}>Telugu Series</h2>
                        <Link to="/lan/series/Telugu">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div>
                    <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"series"} type={"Telugu"}/></Suspense>
                </div>
                {/* <Adcomp id='343751'></Adcomp>mobile */}

                {/*Hindi Series*/}
                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}}>Hindi Series</h2>
                        <Link to="/lan/series/Hindi">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div> <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"series"} type={"Hindi"}/></Suspense>
                </div>
                {/* <Adcomp id='343752'></Adcomp>mobile */}

                {/*Malayalam Series*/}
                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}}>Malayalam Series</h2>
                        <Link to="/lan/series/Malayalam" >
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div>
                    <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"series"} type={"Malayalam"}/></Suspense>
                </div>
                {/* <Adcomp id='343753'></Adcomp>mobile */}
            </div>
            
        </>
    );
}

export default Series;
